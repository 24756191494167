import React from 'react';
import Navigation from '../components/Navigation';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContactForm from '../components/ContactForm';
import { FormattedMessage, useIntl } from 'react-intl';
import { HashLink as Link } from 'react-router-hash-link';

const Home = () => {

    const textTranslator = useIntl();

    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}>
                <Navigation />
                <div className="sections-container">
                    <section className="section" id="home">
                        <div className="wrapper-block">

                            <div className="wrapper-home">
                                <div className="image">
                                    <div className="title">
                                        <h1><FormattedMessage id="home.title" values={{ br: <br /> }} /></h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section className="section" id="presentation">
                        <div className="section-body">
                            <div className="two-columns">
                                <div className="column second">
                                    <h1>
                                        <FormattedMessage id="home.aboutus.title" />
                                    </h1>
                                    <p>
                                        <FormattedMessage id="home.aboutus.description"
                                            values={{ br: <br />, strong: (word) => <strong>{word}</strong>, a: (msg) => <a href="https://fr.audiofanzine.com" title={textTranslator.formatMessage({ id: 'home.alt.gotoaf' })}>{msg}</a> }} />
                                    </p>
                                    <p>
                                        <FormattedMessage id="home.aboutus.description.link" values={{ a: (msg) => <Link to="/values.html" title={textTranslator.formatMessage({ id: 'nav.values' })}>{msg}</Link> }} />
                                    </p>
                                </div>
                                <div className="column">
                                    <img src="/assets/img/presentation.jpg" width="550" height="830" srcSet="/assets/img/presentation.jpg 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.alt.aboutbordas' })} />
                                </div>

                            </div>
                        </div>
                    </section>

                    <section className="section" id="sites">
                        <div className="section-body">
                            <h1><FormattedMessage id="home.sites.title" /></h1>
                            <ul className="">
                                <li>
                                    <NavLink to="/sites/audiofanzine.html" title={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Audiofanzine FR"}>
                                        <img src="/assets/img/websites/af-fr-color.png" width="225" height="25" srcSet="/assets/img/websites/af-fr-color.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Audiofanzine FR"} />
                                        <div className="single-content">
                                            <span className="linkto"><FormattedMessage id="home.sites.button" /></span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sites/audiofanzine-en.html" title={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Audiofanzine EN"}>
                                        <img src="/assets/img/websites/af-en-color.png" width="225" height="25" srcSet="/assets/img/websites/af-en-color.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Audiofanzine EN"} />
                                        <div className="single-content">
                                            <span className="linkto"><FormattedMessage id="home.sites.button" /></span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sites/homerecording.html" title={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Homerecording"}>
                                        <img src="./assets/img/websites/homerecording-new.png" width="225" height="25" srcSet="/assets/img/websites/homerecording-new.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Homerecording"} />
                                        <div className="single-content">
                                            <span className="linkto"><FormattedMessage id="home.sites.button" /></span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sites/futureproducers.html" title={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Futureproducers"}>
                                        <img src="/assets/img/websites/futureproducers.png" width="225" height="54" srcSet="/assets/img/websites/futureproducers.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Futureproducers"} />
                                        <div className="single-content">
                                            <span className="linkto"><FormattedMessage id="home.sites.button" /></span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sites/rigtalk.html" title={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Rigtalk"}>
                                        <img src="/assets/img/websites/rigtalk-logo@2x.png" className='smaller' width="225" height="130" srcSet="/assets/img/websites/rigtalk-logo@2x.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.sites.button' }) + " Rigtalk"} />
                                        <div className="single-content">
                                            <span className="linkto"><FormattedMessage id="home.sites.button" /></span>
                                        </div>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/sites/musicgearreview.html" title={textTranslator.formatMessage({ id: 'home.sites.button' }) + " MusicGear Review"}>
                                        <img src="/assets/img/websites/musicgearreview-logo.png" className='smaller' width="225" height="123" srcSet="/assets/img/websites/musicgearreview-logo.png 2x" loading="lazy" alt={textTranslator.formatMessage({ id: 'home.sites.button' }) + " MusicGear Review"} />
                                        <div className="single-content">
                                            <span className="linkto"><FormattedMessage id="home.sites.button" /></span>
                                        </div>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="section" id="products">
                        <div className="section-body">
                            <h1><FormattedMessage id="home.products.title" /></h1>
                            <ul className="">
                                <NavLink to="/products/advertising.html" title={textTranslator.formatMessage({ id: 'home.products.bloc.products.alt' })}>
                                    <li>
                                        <div className="wrap-header">
                                            <div className="round-image"><img src="./assets/img/products/alert.png" srcSet="./assets/img/products/alert.png 2x" width="30" height="20" loading="lazy" alt="alert icon" /></div>
                                            <h2><FormattedMessage id="home.products.bloc.products.title" /></h2>
                                        </div>
                                        <p><FormattedMessage id="home.products.bloc.products.description" /></p>
                                        <span className='button'><FormattedMessage id="home.products.bloc.button" /></span>
                                    </li>
                                </NavLink>
                                <NavLink to="/products/selling.html" title={textTranslator.formatMessage({ id: 'home.products.bloc.selling.alt' })}>
                                    <li>
                                        <div className="wrap-header">
                                            <div className="round-image"><img src="./assets/img/products/sells.png" srcSet="./assets/img/products/sells.png 2x" width="30" height="20" loading="lazy" alt="sells icon" /></div>
                                            <h2><FormattedMessage id="home.products.bloc.selling.title" /></h2>
                                        </div>
                                        <p><FormattedMessage id="home.products.bloc.selling.description" /></p>
                                        <span className='button'><FormattedMessage id="home.products.bloc.button" /></span>
                                    </li>
                                </NavLink>
                                <NavLink to="/products/services.html" title={textTranslator.formatMessage({ id: 'home.products.bloc.services.alt' })}>
                                    <li>
                                        <div className="wrap-header">
                                            <div className="round-image"><img src="./assets/img/products/services.png" srcSet="./assets/img/products/services.png 2x" width="30" height="20" loading="lazy" alt="services icon" /></div>
                                            <h2><FormattedMessage id="home.products.bloc.services.title" /></h2>
                                        </div>
                                        <p><FormattedMessage id="home.products.bloc.services.description" /></p>
                                        <span className='button'><FormattedMessage id="home.products.bloc.button" /></span>
                                    </li>
                                </NavLink>
                            </ul>
                            <div className='wrap-infos'>
                                <h3><FormattedMessage id="home.products.infos.title" /> </h3>
                                <p>
                                    <FormattedMessage id="home.products.infos.description"
                                        values={{ br: <br />, a: (msg) => <Link to="/#contact" className="nav-link page-scroll" title={textTranslator.formatMessage({ id: 'nav.contact' })}>{msg}</Link> }} />
                                </p>
                            </div>
                        </div>
                    </section>

                    <section className="section" id="team">
                        <div className="section-body">
                            <h1><FormattedMessage id="home.team.title" /></h1>
                            <ul>
                                <li>
                                    <img src="./assets/img/team/armandjohan.png" srcSet="./assets/img/team/armandjohan.png 2x" width="111" height="111" loading="lazy" alt="Armand Johan" />
                                    <span className="title">Armand Johan</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.director" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/nicolasdebarnot.png" srcSet="./assets/img/team/nicolasdebarnot.png 2x" width="111" height="111" loading="lazy" alt="Nicolas Debarnot" />
                                    <span className="title">Nicolas Debarnot</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.presidentcto" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/gregnachbauer.png" srcSet="./assets/img/team/gregnachbauer.png 2x" width="111" height="111" loading="lazy" alt="Grégoire Nachbauer" />
                                    <span className="title">Grégoire Nachbauer</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.editorialchief" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/juliensurlapierre.jpg" srcSet="./assets/img/team/juliensurlapierre.jpg 2x" width="111" height="111" loading="lazy" alt="Julien Surlapierre" />
                                    <span className="title">Julien Surlapierre</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.marketingmanager" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/arnaudcueff.jpg" srcSet="./assets/img/team/arnaudcueff.jpg 2x" width="111" height="111" loading="lazy" alt="Arnaud Cueff" />
                                    <span className="title">Arnaud Cueff</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.editowebdesigner" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/juliengeorges.jpg" srcSet="./assets/img/team/juliengeorges.jpg 2x" width="111" height="111" loading="lazy" alt="Julien Georges" />
                                    <span className="title">Julien Georges</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.salesmanager" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/mathieusoulard.png" srcSet="./assets/img/team/mathieusoulard.png 2x" width="111" height="111" loading="lazy" alt="Mathieu Soulard" />
                                    <span className="title">Mathieu Soulard</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.executiveassistant" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/margauxcaglione.png" srcSet="./assets/img/team/margauxcaglione.png 2x" width="111" height="111" loading="lazy" alt="Margaux Caglione" />
                                    <span className="title">Margaux Caglione</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.fullstackdev" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/arianedavid.jpg" srcSet="./assets/img/team/arianedavid.jpg 2x" width="111" height="111" loading="lazy" alt="Ariane David" />
                                    <span className="title">Ariane David</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.customerservice" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/paulinebouillaud.png" srcSet="./assets/img/team/paulinebouillaud.png 2x" width="111" height="111" loading="lazy" alt="Pauline Bouillaud" />
                                    <span className="title">Pauline Bouillaud</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.news" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/jb.jpg" srcSet="./assets/img/team/jb.jpg 2x" width="111" height="111" loading="lazy" alt="Jean-Baptiste Heuclin" />
                                    <span className="title">Jean-Baptiste Heuclin</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.news.guitar" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/paulparisis.png" srcSet="./assets/img/team/paulparisis.png 2x" width="111" height="111" loading="lazy" alt="Paul Parisis" />
                                    <span className="title">Paul Parisis</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.affiliatecommunitymanager" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/mateodarrault.png" srcSet="./assets/img/team/mateodarrault.png 2x" width="111" height="111" loading="lazy" alt="Matéo Darrault" />
                                    <span className="title">Matéo Darrault</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.videoeditor" /></span>
                                </li>
                                <li>
                                    <img src="./assets/img/team/fredpattebrasseur.png" srcSet="./assets/img/team/fredpattebrasseur.png 2x" width="111" height="111" loading="lazy" alt="Frédéric Patte-Brasseur" />
                                    <span className="title">Frédéric Patte-Brasseur</span>
                                    <span className="subtitle"><FormattedMessage id="home.team.job.forummanager" /></span>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="section" id="references">
                        <div className="section-body">
                            <h1><FormattedMessage id="home.references.title" /></h1>
                            <ul>
                                <li><img src="./assets/img/references/abbey-road.png" srcSet='./assets/img/references/abbey-road.png 2x' alt="Abbey Road" title="Abbey Road" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/adam-audio.png" srcSet='./assets/img/references/adam-audio.png 2x' alt="Admam Audio" title="Admam Audio" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/adam-hall.png" srcSet='./assets/img/references/adam-hall.png 2x' alt="Adam Hall" title="Adam Hall" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/arturia.png" srcSet='./assets/img/references/arturia.png 2x' alt="Arturia" title="Arturia" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/audio-technica.png" srcSet='./assets/img/references/audio-technica.png 2x' alt="Audio Technica" title="Audio Technica" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/avid.png" srcSet='./assets/img/references/avid.png 2x' alt="Avid" title="Avid" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/dpa.png" srcSet='./assets/img/references/dpa.png 2x' alt="DPA" title="DPA" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/expressive-e.png" srcSet='./assets/img/references/expressive-e.png 2x' alt="Expressive E" title="Expressive E" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/genelec.png" srcSet='./assets/img/references/genelec.png 2x' alt="Genelec" title="Genelec" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/gibson.png" srcSet='./assets/img/references/gibson.png 2x' alt="Gibson" title="Gibson" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/ik.png" srcSet='./assets/img/references/ik.png 2x' alt="IK" title="IK" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/ircam.png" srcSet='./assets/img/references/ircam.png 2x' alt="Ircam" title="Ircam" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/lewitt.png" srcSet='./assets/img/references/lewitt.png 2x' alt="Lewitt" title="Lewitt" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/moog.png" srcSet='./assets/img/references/moog.png 2x' alt="Moog" title="Moog" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/plugin-boutique.png" srcSet='./assets/img/references/plugin-boutique.png 2x' alt="Plugin Boutique" title="Plugin Boutique" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/presonus.png" srcSet='./assets/img/references/presonus.png 2x' alt="Presonus" title="Presonus" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/roland.png" srcSet='./assets/img/references/roland.png 2x' alt="Roland" title="Roland" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/sennheiser.png" srcSet='./assets/img/references/sennheiser.png 2x' alt="Sennheiser" title="Sennheiser" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/sonovente.png" srcSet='./assets/img/references/sonovente.png 2x' alt="Sonovente" title="Sonovente" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/soundtoys.png" srcSet='./assets/img/references/soundtoys.png 2x' alt="Soundtoys" title="Soundtoys" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/steinberg.png" srcSet='./assets/img/references/steinberg.png 2x' alt="Steinberg" title="Steinberg" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/thomann.png" srcSet='./assets/img/references/thomann.png 2x' alt="Thomann" title="Thomann" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/tunecore.png" srcSet='./assets/img/references/tunecore.png 2x' alt="Tunecore" title="Tunecore" width={170} height={82} /></li>
                                <li><img src="./assets/img/references/ua.png" srcSet='./assets/img/references/ua.png 2x' alt="UA" title="UA" width={170} height={82} /></li>
                            </ul>
                        </div>
                    </section>
                    <section className="section" id="contact">
                        <div className="section-body">
                            <h1><FormattedMessage id="formcontact.title" /></h1>
                            <div className="wrapper-contact">
                                <div className="left">
                                    <img src="/assets/img/contact/envelope.png" srcSet="/assets/img/contact/envelope.png 2x" width="30" height="24" loading="lazy" alt="envelope icon" />
                                    <ContactForm />
                                </div>
                                <div className="right">
                                    <div>
                                        <img src="/assets/img/contact/building.png" srcSet="/assets/img/contact/building.png 2x" width="30" height="31" loading="lazy" alt="building icon" />
                                        <p className="first">
                                            P. Bordas SAS<br />
                                            9 rue Emile Allez<br />
                                            75017 Paris - France<br />
                                            <FormattedMessage id="formcontact.phone" />: +33 (0) 1 89 16 48 47
                                        </p>
                                    </div>
                                    <div>
                                        <img src="/assets/img/contact/location.png" srcSet="/assets/img/contact/location.png 2x" width="30" height="36" loading="lazy" alt="location icon" />
                                        <p className="first">
                                            <FormattedMessage id="formcontact.job.ceo" />: Nicolas Debarnot<br />
                                            <FormattedMessage id="formcontact.job.generaldirector" />: Armand Johan
                                        </p>
                                        <p>
                                            <FormattedMessage id="formcontact.company.registrationnumber" />: Paris B 310 072 384<br />
                                            <FormattedMessage id="formcontact.company.vatnumber" />: FR 65 310 072 384<br />
                                            <FormattedMessage id="formcontact.company.siretnumber" />: 310 072 384 00079
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </motion.div >

        </div >
    );
};

export default Home;